<!--[自己的卡密]回收卡密-->
<template>
    <div class="view">
        <el-input
            v-model="postData.keyList"
            :rows="10"
            type="textarea"
            :placeholder="lang['html.el-input.placeholder']"
            :disabled="isDisabled"
            v-loading="isLoading"
        />

        <!--        [提交类型] 1=卡密 2=条形码-->
        <el-radio-group v-model="postData.keyType">
            <el-radio :label="1">{{lang['html.el-radio-group.label.1']}}</el-radio>
            <el-radio :label="2">{{lang['html.el-radio-group.label.2']}}</el-radio>
        </el-radio-group>

        <el-tag type="info" style="margin: 10px">{{lang['html.el-tag.placeholder.tips.wait']}}</el-tag>
        <el-tag type="info" style="margin: 10px">{{lang['html.el-tag.placeholder.tips.keyStatus']}}</el-tag>
        <br>

        <el-popconfirm :title="lang['html.el-popconfirm.title']" @confirm="submit">
            <template #reference>
                <el-button type="primary" :disabled="isDisabled">{{lang['html.el-button.submit']}}</el-button>
            </template>
        </el-popconfirm>

        <el-button type="warning" @click="postData.keyList = ''" :disabled="isDisabled">{{lang['html.el-button.reset']}}</el-button>
        <el-tag style="margin-left: 10px">Keys: {{getKeyNum}}</el-tag>
    </div>
</template>

<script>
import {fromBase64} from "js-base64";

export default {
    name: "keyMove",
    data(){
        return {
            isDisabled:false,
            isLoading:true,
            postData:{
                keyList:'',//迁移的卡密列表
                keyType:1,//[提交类型] 1=卡密 2=条形码
            },
            lang:this.$lang.getLang('key','recovery'),
        }
    },
    computed:{
        getKeyNum(){
            let res = this.getKeyListArr();
            if(res){
                return res.length;
            }
            return 0;
        }
    },
    methods:{
        //取过滤后的待操作卡密数组
        getKeyListArr(){
            if (this.postData.keyList == ''){
                return null;
            }
            let arr = this.postData.keyList.split('\n');
            let res = [];
            for (let arrKey in arr) {
                let str = arr[arrKey];
                if (str.length >= 9){
                    res.push(str);
                }
            }
            return res.length === 0 ? null : res;
        },
        //提交请求
        submit(){
            let arr = this.getKeyListArr();
            if (!arr){
                this.$alert(this.lang['submit.errot.keyList']);
                return;
            }
            this.$message(this.lang['submit.wait']);
            this.isLoading = this.isDisabled = true;
            this.$api.post('Key.recovery/batchRecoveryKey',{
                keyList:arr.toString(),
                keyType:this.postData.keyType
            }).then(res=>{
                this.isLoading = this.isDisabled = false;
                let data = this.$helper.checkRes(res);
                if(!data){
                    //调用Api失败,提示错误消息
                    let code = this.$helper.getResErrorCode(res);
                    this.$helper.alertErrMsg(this.lang,'submit.res.code.',code,);
                    return;
                }
                this.$notify(this.lang['submit.result.jump']);
                let item = data.data;
                if (item == null){
                    this.$alert(this.lang['submit.result.error']);
                    return;
                }
                this.$helper.jumpResultPage(item,this.lang,'submit.res.state.',this.lang['submit.res.state.title'])
            }).catch(err=>{
                this.isLoading = this.isDisabled = false;
                this.$helper.axiosCatch(err);
            });
        },

    },
    mounted() {
        let keyList = this.$route.params.keyList;
        if (keyList){
            this.$notify('Importing data..');
            let str = fromBase64(keyList);
            this.postData.keyList = str.replace(/,/g,"\n");
        }
        this.isLoading = false;
    }
}
</script>

<style scoped>
.view{
    width: 450px;
    margin:0 auto;
    margin-top: 20px;
}
</style>
<style scoped>

</style>